<template>
    <div class="game ">
      <div class="panel">
        <div class="iconWrapper">
          <img class="icon" :src="'assets/game_icon_'+gid+'/2x.png'" alt="">
        </div>
        <div class="title">
          <span>{{$t('common.gameNames')[gid]}}</span>
          <span class="rewards">
            {{gid==2?(rewards[0]+'-'+rewards[1]):rewards[0]}} %
          </span>
        </div>
        <div v-if="histories.length>0" class="history bordered animate__animated animate__fadeIn">
          <div v-for="(road,i) in histories" :key="i">
            <div class="road" :class="roadColor(road)">
              <span v-if="gid==0">
                {{$t('common.gameRoadNames')[gid]}}
              </span>
              <span v-if="gid==1">
                {{$t('common.gameRoadNames')[gid][road.road]}}
              </span>
              <span v-if="gid==2">
                {{$t('common.gameRoadNames')[gid][road.road]}}
              </span>
            </div>
          </div>
          
        </div>
        <mu-row gutter v-if="gid==1" class="directions">
          <mu-col span="6">
            <div class="direction blue" @click="directionCheck(1)" :class="direction==1?'on active':''">
              <img src="@/assets/icons/direction_1_1.png" alt="">
              <span class="txt">{{$t('common.odd')}}</span>
              <img v-if="direction==1" class="checked" src="@/assets/icons/player_mark.svg" alt="">
            </div>
          </mu-col>
          <mu-col span="6">
            <div class="direction orange" @click="directionCheck(0)" :class="direction==0?'on active':''">
              <img src="@/assets/icons/direction_1_0.png" alt="">
              <span class="txt">{{$t('common.even')}}</span>
              <img v-if="direction==0" class="checked" src="@/assets/icons/orange_mark.svg" alt="">
            </div>
          </mu-col>
        </mu-row>
        <mu-row gutter v-if="gid==2" class="directions baccarat">
          <mu-col span="6">
            <div class="direction blue" @click="bDirectionCheck(0)" :class="bDirectionClass(0)">
              <span >{{$t('common.player')}}</span>
              <img v-if="bDirection[0]==true" class="checked" src="@/assets/icons/player_mark.svg" alt="">
            </div>
            <div class="direction banker" @click="bDirectionCheck(2)" :class="bDirectionClass(2)">
              <span >{{$t('common.banker')}}</span>
              <img v-if="bDirection[2]==true" class="checked" src="@/assets/icons/banker_mark.svg" alt="">
            </div>
          </mu-col>
          <mu-col span="6">
            <div class="direction yellow" @click="bDirectionCheck(1)" :class="bDirectionClass(1)">
              <img class="icon" src="assets/game_icon_3/1x.png" alt="">
              <span >{{$t('common.tie')}}</span>
              <img v-if="bDirection[1]==true" class="checked" src="@/assets/icons/tie_mark.svg" alt="">
            </div>
          </mu-col>
        </mu-row>
        
        <div class="amounts">
          <div class="label">{{$t('common.chooseBetAmount')}}</div>
          <mu-row gutter class="preset-amounts">
            <mu-col span="4" v-for="a,i in presetAmount" :key="i">
              <div class="btn" @click="plusBet(a,i)" :class="presetAmountOn==i?'on':''">
                <span>{{a}}</span>
                <span v-if="presetAmountOn==i" class="ani-plus" :class="aniPlus==true?'animate__animated animate__fadeOutUp':''">+</span>
              </div>
            </mu-col>
          </mu-row>
          <div class="info">
            {{$t('common.moneyMustBetween',{minAmount: amountLimit[0],maxAmount:amountLimit[1]})}}
          </div>
        </div>
        
        <div v-if="gid==2">
          <!-- <div v-for="ba,i in bBetAmount" :key="'ba'+i">
            <div v-if="ba>0" class="bets_clear flex bordered">
              <div>
                <span>
                  {{i==0?$t('common.player'):(i==2?$t('common.banker'):$t('common.tie'))}}
                </span>
              </div>
              <span class="flex-1">{{ba}}USDT</span>
              <span class="clear" @click="clearBets(i)">{{$t('bet.clear')}}</span>
            </div>
          </div> -->
          <div v-for="ba,i in bBetAmount" :key="'bab'+i">
            <div v-if="ba>0" class="bets flex-justify bordered" @click="bDirectionCheck(i)" :class="bBetsColor(i)">
              <div>
                <span class="direction">{{(i==0?$t('common.player'):(i==2?$t('common.banker'):$t('common.tie'))).substr(0,1)}}</span>
              </div>
              <div class="bet flex flex-1 column">
                <span class="num">{{ba}}</span>
                <span>{{$t('bet.betAmount')}}</span>
              </div>
              <div class="divider"></div>
              <div class="earn flex flex-1 column">
                <span class="num">{{ba * rewards[i] / 100}}</span>
                <span>{{$t('bet.mostEarn')}}</span>
              </div>
              <mu-icon class="clear" value="cancel" @click="clearBBets(i)"></mu-icon>
            </div>
          </div>

        </div>
        <div v-else>
          <div v-if="betAmount>0">
            <div class="bets_clear flex bordered">
              <div>
                <span v-if="gid==1">
                  <img v-if="direction==1" src="@/assets/icons/direction_1_1.png" alt="">
                  <img v-if="direction==0" src="@/assets/icons/direction_1_0.png" alt="">
                </span>
              </div>
              <span>{{betAmount}}SDD</span>
              <span class="clear" @click="clearBets">{{$t('bet.clear')}}</span>
            </div>
            <div class="bets flex-justify bordered">
              <div class="bet flex flex-1 column">
                <span class="num">{{betAmount}}</span>
                <span>{{$t('bet.betAmount')}}</span>
              </div>
              <div class="divider"></div>
              <div class="earn flex flex-1 column">
                <span class="num">{{betAmount * rewards[0] / 100}}</span>
                <span>{{$t('bet.mostEarn')}}</span>
              </div>
            </div>
          </div>
        </div>
        
        <div class="btns">
          <div class="win flex flex-1">
            <div class="btn primary" @click="bet">{{$t('common.winBtn')}}</div>
          </div>
          <div class="tag flex" @click="goRecords">
            <img src="@/assets/icons/tag.svg" alt="">
          </div>
          
        </div>
      </div>
    </div>
</template>
<script>
import config from '../config';
export default {
  name: 'Game',
  data(){
    return {
      histories:[],
      gid:this.$store.state.currentGameId,
      rewards: this.$store.state.gameRewards[this.$store.state.currentGameId],
      presetAmount: config._GAME_PRESET_AMOUNT,
      presetAmountOn: null,
      amountLimit: [10,10000],
      direction:0,
      bDirection: [true,false,false],
      betAmount: 0,
      bBetAmount: [0,0,0],
      aniPlus:false,
      // approvedAmount: this.$store.state.approvedAmount,
    }
  },
  mounted(){
    this.$store.commit("setClosablePage",true);
    let _this = this;
    let obj = setInterval(async ()=>{
      if (window.walletAddress && window.HASH_CONTRACT && window.USDT_TOKEN) {
          clearInterval(obj)
          //debug
          console.log(window.HASH_CONTRACT)
          window.HASH_CONTRACT.game().call().then((res)=>{
            let _minAmount = typeof(res.minAmount)=='object' ? res.minAmount.toNumber():res.minAmount;
            let _maxAmount = typeof(res.maxAmount)=='object' ? res.maxAmount.toNumber():res.maxAmount;

            // _this.minAmount = _minAmount;
            // _this.maxAmount = _maxAmount-1;
            _this.amountLimit = [_minAmount, _maxAmount-1];
            console.log(_this.amountLimit);
          });
          // window.HASH_CONTRACT.getGameRewards().call().then((res)=>{
          //   // console.log(res)
          //   _this.gameRewards = res
          // });
          // //debug
          _this.getAllowance();
          setTimeout(()=>{
            _this.getRoads();
          },1000)
      }
    }, 50);
    
  },
  components:{
  },
  methods:{
    async bet(){
      let _this = this;
      let _value = _this.gid==2?_this.bBetAmount:[_this.betAmount,0,0];
      let _sum = _value.reduce((acr, cur)=>{
        return acr + cur;
      });
      
      if(_sum<_this.amountLimit[0] || _sum>_this.amountLimit[1]){
        this.$toast.error(
          this.$t('common.moneyMustBetween',{minAmount:_this.amountLimit[0],maxAmount:_this.amountLimit[1]}),
        );
        return;
      }
      let _direction = 0;
      if(this.gid==2){
        let _d=0;
        for(let i=0;i<=2;i++){
          if(_this.bBetAmount[i]>0){
            _d++;
            _direction = [0,2,1][i];
          }
          _value[i] = _value[i]*1000000;
          //debug
          // _value[i] = _value[i]*100;
        }
        _direction = (_d>=2?3:_direction);
      }else{
        for(let i=0;i<=2;i++){
          _value[i] = _value[i]*1000000;
          //debug
          // _value[i] = _value[i]*100;
        }
        _direction = this.direction;
      }
      let _nonce = Math.floor(Math.random()*10000);
      _this.bBetAmount = [0,0,0];
      if(_sum*1000000>this.$store.state.approvedAmount){
        window.USDT_TOKEN.increaseApproval(window._HS_CONTRACT_ADDR, _sum*1000000).send().then((res)=>{
          console.log(res)
          _this.$toast.success(this.$t('common.submitSuccess'));
          window.HASH_CONTRACT.bet(_this.gid,_direction,_value,_nonce).send().then(()=>{
            _this.$toast.success({
              message: _this.$t('tips.goodLuck'),
              // description: _this.$t('tips.youWillGet',{reward: _mostEarn + _this.currency}),
              time: 5000
            });
            // _this.lastBetData = {
            //   gid: game_id,
            //   zxValue: is_banker,
            //   value: value
            // };
            // _this.clearBets();
          });
          setTimeout(()=>{
            _this.getAllowance();
          },1500)
        });
        return;
      }
      console.log({gid:this.gid,direction:_direction,value:_value,nonce:_nonce});
      try{
        await window.HASH_CONTRACT.bet(_this.gid,_direction,_value,_nonce).send().then(()=>{
          _this.$toast.success({
            message: _this.$t('tips.goodLuck'),
            // description: _this.$t('tips.youWillGet',{reward: _mostEarn + _this.currency}),
            time: 5000
          });
          // _this.lastBetData = {
          //   gid: game_id,
          //   zxValue: is_banker,
          //   value: value
          // };
          // _this.clearBets();
        });
      }catch(e){
        console.log(e)
      }
    },
    directionCheck(direction){
      this.direction = direction;
    },
    bDirectionCheck(index){
      // let _checked = this.bDirection[index];
      this.$set(this.bDirection, index, true);
      this.direction = index;
    },
    bDirectionClass(i){
      // let _on = this.bDirection[i]==true?' on':'';
      // let _on = this.bBetAmount[i]>0?' on':'';
      let _active = this.direction==i?' on active':'';
      return _active;
    },
    bBetsColor(i){
      let _colors = ['blue','yellow','banker'];
      // let _active = this.direction==i?' active':'';
      return _colors[i]+(this.direction==i?' active':'');
    },
    clearBets(){
      this.betAmount = 0;
    },
    clearBBets(i){
      this.$set(this.bBetAmount, i, 0);
      // for(let i=0;i<=2;i++){
      //   if(this.bBetAmount[i]<=0){
      //   }
      // }
      // this.$set(this.bDirection, i, false);

    },
    plusBet(b,i){
      let _value = this.gid==2?this.bBetAmount:[this.betAmount,0,0];
      let _sum = _value.reduce((acr, cur)=>{
        return acr + cur;
      });
      
      if(_sum + b > this.amountLimit[1]){
        this.$toast.error({
          "message":this.$t('tips.maxBetValue',{value:this.amountLimit[1]}),
          // "time": 50000,
        });
        return;
      }
      
      this.presetAmountOn = i;
      this.aniPlus = true;
      setTimeout(()=>{
        this.aniPlus = false;
        this.betAmount += b;
      },200);
      if(this.gid==2){
        let _amount = this.bBetAmount[this.direction] + b;
        this.$set(this.bBetAmount, this.direction, _amount);
        console.log(this.bBetAmount);
      }
      
    },
    getRoads(){
      let _this = this;
      let data = {address: window.walletAddress,gid:this.gid};
      this.$http.call(this.$http.api.roads, {
        params: data
      }).then(function(res){
        console.log(res)
        // _this.$store.commit("setRoads", res.data);
        _this.histories = res.data;
      },function(){
        console.log('something goes wrong...');
        // console.log(res);
      });
    },
    roadColor(road){
      // if(!road.win) return 'green';
      // if(!road.win && this.gid==0) return 'green';
      
      if(this.gid==0){
        return road.win ? 'on':'green';
      }
      if(this.gid==1){
        return road.road==0?'orange':'blue';
      }
      if(this.gid==2){
        return road.road==1?'on':(road.road==0?'blue':'red');
      }      
    },
    goRecords(){
      this.$router.push({name:'records'});
    },

    getAllowance(){
      let _this = this;
      window.USDT_TOKEN.allowance(window.walletAddress, window._HS_CONTRACT_ADDR).call().then((res)=>{
        let _approveAmount=0;
        if(res.remaining===undefined){
          _approveAmount = res.toNumber();
        }else{
          _approveAmount = res.remaining.toNumber();
        }
        _this.$store.commit("setApprovedAmount", _approveAmount);
      });
    },
    approve(val){
      let _this = this;
      window.USDT_TOKEN.increaseApproval(window._HS_CONTRACT_ADDR, val*1000000).send().then((res)=>{
        console.log(res)
        _this.$toast.success(this.$t('common.submitSuccess'));
        setTimeout(()=>{
          _this.getAllowance();
        },1500)
      });
    },
  }
}
</script>
<style lang="less" scoped>
.game{
  display: flex;
  flex-direction: column;
  margin-top: -28px;
  .panel{
    background: @bg-white;
    margin: 0 30px;
    border-radius: 35px;
    color: @bg-color;
    box-shadow: 0px 20px 30px 0px rgba(0,0,0,0.26);
    position: relative;
    padding: 80px 28px;
    .iconWrapper{
      background: @bg-white;
      padding: 7px;
      border-radius: 50%;
      position: absolute;
      top: -72px;
      left: 50%;
      margin-left: -64px;
      .icon{
        width: 128px;
        border-radius: 50%;
      }
    }
    .title{
      padding: 24px 28px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 7px;
      background: linear-gradient(to bottom, #e7603f, #e14737);
      color: #fae7d1;
      .rewards{
        scale: 1.1;
        font-weight: bold;
        color: #fff;
      }
    }
    .bordered{
      margin: @base-pd 0;
      padding: @base-pd;
      border-radius: 7px;
      border: 1px solid @btn-color;
    }
    .history{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      overflow-y: scroll;
      padding: 6px;
      max-height: 108px;
      .road{
        // padding: 5px 7px;
        width: 32px;height:32px;
        line-height: 32px;
        border-radius: 7px;
        margin: 7px;
        text-align: center;
        color: @bg-white;
      }.on{
        background: @primary-color;
      }.green{
        background: #08DD6C;
      }.blue{
        background: #1B8FFF
      }.orange{
        background: #FD752A;
      }.red{
        background: #FA2700;
      }
    }
    .directions{
      // display: flex;
      // justify-content: space-around;
      margin-top: @base-pd;
      // padding: @base-pd 0;
      .direction{
        border: 1px solid @bg-color;
        padding: 12px;
        border-radius: 6px;
        flex: 1;
        margin:7px 0;
        position:relative;
        display:flex;
        align-items: center;
        justify-content: center;
        .txt{
          padding-left: 10px;
        }
        .checked{
          position: absolute;
          top: -22px;
          right: -23px;
          display: none;
        }
      }.blue{
        border-width: 2px;
        border-color: @blue;
      }.blue.on.active{
        box-shadow: 0px 0px 8px 0px @blue;
        .checked{
          display:block;
        }
      }.banker{
        border-width: 2px;
        border-color: @red;
      }.banker.on.active{
        box-shadow: 0px 0px 8px 0px @red;
        .checked{
          display: block;
        }
      }.orange{
        border-width: 2px;
        border-color: @orange;
      }.orange.on.active{
        box-shadow: 0px 0px 8px 0px @orange;
        .checked{
          display: block;
        }
      }.yellow{
        border-width: 2px;
        border-color: @primary-color;
      }.yellow.on.active{
        box-shadow: 0px 0px 8px 0px @primary-color;
        .checked{
          display:block;
        }
      }.yellow{
        padding: 20px;
        justify-content: flex-start;
        .icon{
          margin-right: 15px;
          // border: 1px solid @bg-color;
          border-radius: 50%;
          width: 80px;
        }
      }
    }
    .amounts{
      text-align: left;
      .label{
        padding-top: @base-pd;
      }
      .preset-amounts{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .btn{
          background: @primary-color-shadow;
          color: #777;
          text-align: center;
          padding: 24px;
          margin: 12px 0;
          box-shadow: none;
          position: relative;
        }.on{
          background: @primary-color;
          font-weight: bold;
          color: #fff;
          box-shadow: 0px 10px 20px 0px @primary-color-shadow;
          .ani-plus{
            position: absolute;
            color: #fff;
            top: 20px;
            right: 30px;
            display: none;
            font-size: 36px;
          }.animate__animated{
            display: block;
          }
        }
      }
    }
    .bets_clear{
      justify-content: space-between;
      border-color: @primary-color;
      color: @primary-color;
    }
    .bets{
      .num{
        color: #e14737;
        font-size: 32px;
        font-weight: bold;
      }
      .divider{
        width: 1px;height:30px;
        background: @bg-color;
        opacity: .2;
      }
      .direction{
        width: 36px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        display: block;
        border-radius: 50%;
        color: #fff;
      }
      .bet{
        flex:1;
      }
      .earn{
        flex:1;
      }
    }.blue{
      border-color: @blue;
      .num,.clear{
        color: @blue;
      }
      .direction{
        background-color: @blue;
      }
    }.blue.active{
        box-shadow: 0px 0px 8px 0px @blue;
    }.banker{
      border-color: @red;
      .num,.clear{
        color: @red;
      }
      .direction{
        background-color: @red;
      }
    }.banker.active{
      box-shadow: 0px 0px 8px 0px @red;

    }.yellow{
      border-color: @primary-color;
      .num,.clear{
        color: @primary-color;
      }
      .direction{
        background-color: @primary-color;
      }
    }.yellow.active{
      box-shadow: 0px 0px 8px 0px @primary-color;

    }
    .btns{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: @base-pd;
      .btn{
          background: #e7603f;
          text-align: center;
          color: #fff;
          font-weight: 700;
          padding: 20px;
          font-size: 28px;
          border-radius: 15px;
          flex: 1;
      }.primary{
          // background: @primary-color;
          box-shadow: 0px 10px 20px 0px @primary-color-shadow;
      }
      .tag{
        border: 0.5px solid @bg-color;
        padding: 10px;
        border-radius: 6px;
        margin-left: @base-pd;
      }
    }
  }
}

</style>
